// Colors
$dark: $vita-dark;

// Options
$enable-rounded: false;

// Typography
$headings-font-family: $headings-font;
$headings-font-weight: 600;
$display1-weight: 200;
$display2-weight: 200;
$display3-weight: 200;
$display4-weight: 200;
