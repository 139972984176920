footer {
  background: $vita-dark;
  color: $white;
  font-weight: 200;

  p {
    font-size: 85%;
  }

  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .footer-logo {
    width: 100%;
    max-width: 160px;
  }

  small {
    color: lighten($vita-dark, 30%);

    a {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }
}
