.split-insert-img {
  .split-img {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.limit-height {
      img {
        @include media-breakpoint-up(lg) {
          max-height: 45vw;
        }
      }
    }
  }

  .insert-tagline {
    max-width: 600px;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .insert-text {
    font-size: 1.125rem;
    font-weight: 300;
  }

  .insert-mission {
    color: darken($vita-light, 5%);
    text-transform: uppercase;
  }
}

.split-insert-youtube {
  .insert-tagline {
    max-width: 600px;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .insert-text {
    font-size: 1.125rem;
    font-weight: 300;
  }
}

.key-numbers {
  color: $white;
  font-family: $headings-font-family;
  text-shadow: 0 0 2px rgba($black, .5);

  .key-number {
    .display-1 {
      font-weight: 600;
    }
  }

  .key-text {
    font-size: 1.25rem;
    letter-spacing: 4px;
  }
}

.missions {
  color: $white;
  font-family: $headings-font-family;
  text-shadow: 0 0 2px rgba($black, .5);
  background: rgba($black, .5);

  hr {
    background-color: rgba($white, .75);
  }

  .mission-heading {
    text-transform: uppercase;
  }

  .key-text {
    font-size: 1.25rem;
    letter-spacing: 4px;
  }
}

.innovations-carousel {
  .innovations-title {
    text-align: center;
  }

  .innovations-tagline {
    max-width: 560px;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .innovations-link {
    text-align: center;
  }

  .carousel-item {
    background: $black;

    img {
      opacity: .8;
    }
  }

  .carousel-logo {
    position: absolute;
    top: 5%;
    right: 50%;
    width: 40%;
    margin-right: -20%;
    padding: .75rem;
    background: rgba($white, .9);
    z-index: 10;

    @include media-breakpoint-up(sm) {
      width: 30%;
      right: 5%;
      margin-right: auto;
    }

    @include media-breakpoint-up(lg) {
      width: 20%;
    }
  }

  .slider-title {
    text-shadow: 0 0 2px rgba($black, .5);
  }

  .slider-text {
    color: $vita-dark;
    background: rgba($white, .8);
    padding: .5rem 1rem;

    p {
      margin-bottom: 0;
    }
  }

  .slider-link {
    a span {
      text-transform: uppercase;
    }
  }
}

.latest-news {
  .latest-news-title {
    text-align: center;
  }

  .news-tagline {
    max-width: 560px;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .prev-next-heading {
    text-transform: uppercase;
    color: rgba($black, .25);
  }

  .news-card {
    border: none;
    background: $vita-dark;

    .card-img {
      opacity: 1;
      transition: opacity .125s linear;
    }
  }

  .news-link {
    &:hover {
      .card-img {
        opacity: .5;
      }
    }
  }

  .card-img-overlay {
    top: 50%;
    padding: 0;

    .card-title {
      display: inline-block;
      background: rgba($black, .75);
      color: $white;
      font-weight: 400;
      padding: 1rem;
    }
  }
}

.text-block {
  .block-tagline {
    max-width: 600px;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .block-text {
    font-size: 1.125rem;
    font-weight: 300;
  }
}

.brands-solutions {
  .brands-solutions-title {
    text-align: center;
  }
}

.timeline {
  background: rgba($black, .75);
  color: $white;

  .year {
    font-size: 2.5rem;
    line-height: 1;

    &::after {
      content: '';
      position: absolute;
      top: 3.5rem;
      left: calc(-.75rem  - 1px);
      right: auto;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background: $white;
    }
  }

  .brands {
    width: 100%;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 3.5rem;
      left: -100%;
      height: 1.5rem;
    }
  }

  .col-1.col-md-6 {
    border-right: 1px solid $white;
  }

  .col-11.col-md-6 {
    border-left: 1px solid $white;
  }

  .flex-md-row {
    @include media-breakpoint-up(md) {
      .year {
        &::after {
          right: calc(-.75rem  - 1px);
          left: auto;
        }
      }

      .brands {
        left: 100%;
      }

      .col-md-6 {
        &:first-child {
          border-right: 1px solid $white;
          border-left: none;
        }

        &:last-child {
          border-right: none;
          border-left: 1px solid $white;
        }
      }
    }
  }
}

.dual-columns-text {
  .column-text {
    font-size: 1.125rem;
    font-weight: 300;
  }

  .bg-dark {
    color: $white;

    hr {
      background-color: rgba($white, .25);
    }
  }
}

.quotes-trilogy {

  .card {
    position: relative;

    .card-img-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      overflow: hidden;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .25;
      }
    }

    .card-body {
      z-index: 10;
      color: $white;

      .quote-heading {
        color: $yellow;
      }

      hr {
        background-color: rgba($white, .25);
      }

      .quote-text {
        font-size: 1.125rem;
        font-weight: 300;
        font-style: italic;
      }
    }
  }
}

.jobs-offerings {
  .jobs-title {
    text-align: center;
  }

  .jobs-tagline {
    max-width: 560px;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .jobs-card {
    position: relative;
    border: none;
    background: $vita-dark;

    .card-img {
      opacity: 1;
      transition: opacity .125s linear;
    }
  }

  .jobs-link {
    &:hover {
      .card-img {
        opacity: .5;
      }
    }
  }

  .jobs-brand {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 30%;
    padding: .75rem;
    background: rgba($white, .9);
    z-index: 10;

    &.brand-contrast {
      background: rgba($black, .75);
    }

    @include media-breakpoint-up(sm) {
      width: 25%;
    }
  }

  .card-img-overlay {
    top: 50%;
    padding: 0;

    .card-title {
      display: inline-block;
      background: rgba($black, .75);
      color: $white;
      font-weight: 400;
      padding: 1rem;
    }
  }

  .iframe-wrapper {
    height: 100vh;
    min-height: 500px;
  }
}

.candidate-form {
  .candidate-title {
    text-align: center;
  }

  .candidate-tagline {
    max-width: 560px;
    font-size: 1.25rem;
    font-weight: 600;
  }
}
