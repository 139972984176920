#top-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .header-logo {
    width: 100%;
    max-width: 200px;

    @include media-breakpoint-up(md) {
      max-width: 260px;
    }
  }

  .nav-link {
    position: relative;
    font-family: $headings-font-family;
    color: $white;
    text-transform: uppercase;
    text-shadow: 0 0 2px rgba($black, .5);

    &::before {
      content: "";
      position: absolute;
      top: auto;
      bottom: 0;
      left: 50%;
      right: 50%;
      width: auto;
      height: 2px;
      background: $white;
      box-shadow: 0 0 2px rgba($black, .25);
      transition: left .125s linear, right .125s linear;
    }

    &:hover,
    &.active {
      text-decoration: none;

      &::before {
        left: 0;
        right: 0;
      }
    }
  }

  .dropdown-menu {
    font-family: $headings-font-family;
    margin: 0;
    border: none;
    border-radius: 0;
    background: rgba(255,255,255,.5);
  }

  .dropdown:hover {
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-item {
    &:hover {
      text-decoration: none;
      background-color: $white;
    }

    &.active {
      background: $vita-dark;
    }
  }

  .lang-switcher {
    position: absolute;
    top: 4rem;
    right: 15px;

    a {
      display: block;
      width: 36px;
      background-color: $white;
      border-radius: 50%;
      padding: .25rem;
      transition: all .15s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

#float-header {
  position: fixed;
  top: 4rem;
  left: 15px;
  z-index: 10;
  opacity: 1;
  transition: opacity .125s linear;

  @include media-breakpoint-up(md) {
    opacity: 0;
  }

  #burger-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 38px;
    height: 38px;
    cursor: pointer;
    background: transparent;
    transition: all .125s;
    z-index: 11;

    .line-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: $white;
      border-radius: .25rem;
      transform: scale(1);
      transition: transform .15s ease-in-out;
    }

    .line {
      position: absolute;
      height: 6px;
      background: darken($vita-light, 10%);
      transform: rotate(0);
      transition: all .15s ease-in-out;

      &:nth-child(1) {
        width: calc(65% - .5rem);
        top: .25rem;
        left: .25rem;
      }
      &:nth-child(2) {
        width: calc(90% - .5rem);
        top: 50%;
        left: .25rem;
        margin-top: -3px;
      }
      &:nth-child(3) {
        width: calc(65% - .5rem);
        bottom: .25rem;
        left: .25rem;
      }
    }

    &:hover {
      .line {
        width: calc(100% - .5rem);
      }
    }
  }

  #menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($white, .75);
    opacity: 0;
    transition: opacity.115s linear;
    pointer-events: none;
  }

  #slide-menu {
    position: fixed;
    top: 0;
    left: -100%;
    right: auto;
    width: 100%;
    max-width: 320px;
    height: 100%;
    background: rgba($black, .75);
    padding: 6.5rem 2.2rem 1rem;
    transition: all .125s ease-in-out;
    overflow-y: auto;

    .nav-link {
      position: relative;
      color: $white;
      font-family: $headings-font-family;
      font-weight: 400;
      text-transform: uppercase;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -.4rem;
        left: -.4rem;
        width: .8rem;
        height: .8rem;
        border-radius: 50%;
        background: $white;
        opacity: 0;
        transition: opacity .125s linear;
      }

      &:hover,
      &.active {
        text-decoration: none;

        &::before {
          opacity: 1;
        }
      }
    }

    .dropdown-menu {
      position: static;
      display: block;
      font-family: $headings-font-family;
      margin: 0;
      border: none;
      border-radius: 0;
      background: rgba(255,255,255,.5);
      height: $group-menu-subitems * ((1.25rem * 1.5) + 1rem);
      overflow: hidden;
      transition: height .1s linear;
    }

    @include media-breakpoint-up(md){
      .dropdown-menu {
        height: 0;
      }

      .dropdown:hover {
        .dropdown-menu {
          height: $group-menu-subitems * ((1.25rem * 1.5) + 1rem);
        }
      }
    }

    .dropdown-item {
      font-size: 1.25rem;

      &:hover {
        text-decoration: none;
      }

      &.active {
        background: $vita-dark;
      }
    }
  }

  &.open {
    #burger-btn {
      .line {
        width: calc(100% - .5rem);

        &:nth-child(1) {
          top: 50%;
          margin-top: -3px;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          bottom: 50%;
          margin-bottom: -3px;
          transform: rotate(-45deg);
        }
      }

      &:hover {
        .line-wrapper {
          transform: scale(1.125);
        }
      }
    }

    #menu-backdrop {
      opacity: 1;
    }

    #slide-menu {
      left: 0;
    }
  }

  &.displayed {
    opacity: 1;
  }
}

#social-links {
  position: fixed;
  top: 7rem;
  right: 15px;
  z-index: 11;

  a {
    color: darken($vita-light, 10%);
    background-color: $white;
    padding: .1rem .25rem;
    border-radius: .25rem;
    font-size: 2rem;
    line-height: 1;
    margin-bottom: .5rem;
    transition: all .15s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }
}
