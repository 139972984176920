html, body {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

strong {
  font-weight: 600;
}

.browser-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba($white, 0.8);
  z-index: 9000;
}

.preprod-badge {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border: 1px solid $gray-300;
  z-index: 1000;
}

.video-wall {
  height: 100%;
}

.video-header {
  height: 75%;
}

.video-wall,
.video-header {
  width: 100%;
  overflow: hidden;

  video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1s opacity;
  }

  .tagline {
    color: $white;
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 0 2px 4px rgba($black, .5);

    em {
      position: relative;
      font-style: normal;

      &::after {
        content: "";
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        height: 4px;
        background: $white;
        box-shadow: 0 2px 4px rgba($black, .25);
      }
    }
  }
}

.vita-title {
  font-weight: 200;
  text-transform: uppercase;
}

.bg-contrast {
  background: $vita-light;
}

.page-item {
  .page-link {
    color: $dark;
  }

  &.active {
    .page-link {
      background-color: $dark;
      border-color: $dark;
      color: $white;
    }
  }
}

.btn {
  &:hover {
    text-decoration: none;
  }
}

.reset-btn {
  color: $gray-600;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $vita-dark;
  }
}

.form-alert {
  display: none;
}

#cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid $secondary;
}
