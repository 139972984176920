@charset "utf-8";

/* -- Google Fonts -- */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');

/* -- Variables -- */
$headings-font: 'Poppins', sans-serif;
$vita-dark: rgb(35, 35, 35);
$vita-light: rgb(232, 232, 232);
$vita-accent: #0088ae;
$group-menu-subitems: 4;

/* -- font-awesome path override -- */
$fa-font-path: "../fonts";

/* -- Imports -- */
@import "partials/bs-custom";
@import "modules/bootstrap";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "partials/baseof";
@import "partials/header";
@import "partials/footer";
@import "partials/shortcodes";
